<template>
	<div class="recipes background-aili-pink">
		<Header />
		<div class="content">
			<div
				v-infinite-scroll="loadMore"
				:infinite-scroll-disabled="busy"
				:infinite-scroll-distance="0"
				infinite-scroll-throttle-delay="1600"
			>
				<MainColumn v-if="tag">
					<p class="top-title color-aili-white font-size-50">
						Ricette
					</p>
					<CategoryHeaderRecipes v-if="recipeCategories.length > 0" :term="slug" />
					<div class="panel background-aili-white">
						<div v-if="$vuetify.breakpoint.width >= 1024" class="color-aili-pink font-size-35">
							Playlist Senza Lattosio
						</div>
						<div class="color-aili-pink font-size-35 pb-5">
							{{ tag.name }}
						</div>
						<p class="description font-size-20" v-html="tag.description"></p>
						<div v-for="(post, i) in recipes" :key="i">
							<CardResultRecipe
								:slug="post.slug"
								:preview_data="post.preview_data"
								:author="post.author_data"
								:url="post.relative_url"
								:image="post.featured_media_data"
								:title="post.title.rendered"
								:content="post.post_excerpt"
								:categories="post['recipe-category_data']"
							/>
						</div>
					</div>
				</MainColumn>
			</div>
		</div>
	</div>
</template>

<script>
import Header from '@/components/ui/Header.vue';
import CategoryHeaderRecipes from '@/components/ui/CategoryHeaderRecipes.vue';
import CardResultRecipe from '@/components/ui/CardResultRecipe.vue';

export default {
	components: {
		Header,
		CategoryHeaderRecipes,
		CardResultRecipe,
	},
	data() {
		return {
			tag: null,
			categories: [],
			recipes: [],
			slug: '',
			page: 1,
			per_page: 3,
			max_page: 999,
			busy: false,
			end: false,
		};
	},
	async mounted() {
		this.updateProperty({
			property: 'pageColor',
			value: 'aili-pink',
		});
		this.updateProperty({
			property: 'loadingData',
			value: true,
		});
		await this.getRecipeCategories();

		let slug = this.$route.params.tag;
		let response_tag = await this.$api.get('/wp/v2/tags', {
			params: {
				slug: slug,
			},
		});

		this.tag = response_tag.data[0];
		let response_recipes = await this.$api.get('/wp/v2/recipe?tags[]=' + this.tag.id);
		this.recipes = response_recipes.data;
		this.updateProperty({
			property: 'loadingData',
			value: false,
		});
	},

	methods: {
		async loadMore() {
			try {
				if (parseInt(this.page) < parseInt(this.max_page) && this.slug !== '') {
					this.busy = true;
					this.page = this.page + 1;
					let response = await this.$api.get('/wp/v2/recipe', {
						params: {
							hide_empty: true,
							'recipe-category-slug': this.slug,
							per_page: this.per_page,
							page: this.page,
						},
					});
					if (response.code != 'rest_post_invalid_page_number') {
						let result = this.posts_by_term.concat(response.data);
						this.$set(this, 'posts_by_term', result);
						this.busy = false;
					} else {
						this.max_page = this.page;
						this.end = true;
					}
				}
			} catch (e) {
				this.max_page = this.page;
				this.end = true;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.recipes {
  min-height: 100vh;
  padding-bottom: $footer-distance;
}

h1 {
  padding: $spacing-1 0;
}

h2,
h3 {
  margin-bottom: $spacing-0;
}
.description {
  max-width: 750px;
  margin-bottom: $spacing-1;
}

.panel {
  position: relative;
  padding: $spacing-1;

  @media (max-width: $tablet-m) {
    padding: $spacing-1 $spacing-0;
  }

  @media (max-width: $mobile-m) {
    padding: $spacing-1 $spacing-0/2;
  }
}
</style>
